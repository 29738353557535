import React, { useState, useEffect, useCallback, useRef } from "react";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import Post from "../post";
import "./css/IP.css";
const config = require('../config');
document.title = "Der Spicker";

export default function IndexPage() {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [numArticles, setNumArticles] = useState(0);
  const [noPostsToLoad, setNoPostsToLoad] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [maintenanceError, setMaintenanceError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const hasFetched = useRef(false);
  const postCounter = useRef(0);

  const [isSearchActive, setIsSearchActive] = useState(false);

  const [showAd, setShowAd] = useState(false);
  const [cookies, setCookie] = useCookies(['hideAd']);
  const [cookieConsent, setCookieConsent] = useCookies(['agreedToCookieConsent'])
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const loadMorePosts = useRef(() => {});
  function loadMore() {
    hasFetched.current = false;
    loadMorePosts.current();
  }

  useEffect(() => {
    if (!cookies.hideAd) {
      setShowAd(true);
    }
    if(!cookieConsent.agreedToCookieConsent) {
      setShowCookieConsent(true)
    }
  }, [cookies, cookieConsent]);
  
  const closeAd = () => {
    setShowAd(false);
    setCookie('hideAd', true, { expires: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000) });
  };
  
  const closeCookieConsent = () => {
    setShowCookieConsent(false);
    setCookieConsent('agreedToCookieConsent', true, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
  }

  useEffect(() => {
    const postElements = document.querySelectorAll('.Post');
    postElements.forEach((postElement, index) => {
      postElement.style.animationDelay = `${(index - postCounter.current) * 0.15}s`;
      postElement.classList.add('pop-in');
    });
    postCounter.current = postElements.length;
  }, [posts]);



  loadMorePosts.current = useCallback(() => {
    setLoading(true);
    setError(false);
    let isCancelled = false
    if(!hasFetched.current) {
    let fetchUrl = `${config.backendIP}/postcard?numArticles=${numArticles}`;
    if (selectedCategories) {
      fetchUrl += `&category=${selectedCategories}`;
    }
    if(!hasFetched.current) {
      hasFetched.current = true;
    fetch(fetchUrl)
      .then((response) => {
        if (!isCancelled) {
        if (response.status === 204) {
          setMaintenanceError(true);
          throw new Error("Website is currently undergoing maintenance");
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        hasFetched.current = true;
        return response.json();
      }})
      .then((newPosts) => {
        if (!isCancelled) {
          if (newPosts.length > 0) {
            setNumArticles(numArticles + newPosts.length);
            setPosts((prevPosts) => {
            const filteredNewPosts = newPosts.filter((newPost) => {
              return !prevPosts.some((prevPost) => {
                return newPost._id === prevPost._id;
              });
            });
            return [...prevPosts, ...filteredNewPosts];
          });
        } else {
          setNoPostsToLoad(true);
        }
        setLoading(false);
      }})
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });

      return () => {
        isCancelled=true
      }
      
     
  }}}, [numArticles, selectedCategories]);

  useEffect(() => {
    loadMorePosts.current();
  }, []);


  useEffect(() => {
    var loader = document.getElementById("loader");
    if (!loading) {
      loader.className += " hidden";
    }
  }, [loading, loadMorePosts]);


  const handleCategoryClick = (category) => {
    if(category === "delete") {
      setSelectedCategories([]);
      updatePosts();
    }
    else {
    let newCategories = [...selectedCategories];
    if (newCategories.includes(category)) {
        newCategories = newCategories.filter(cat => cat !== category);
    } else if (newCategories.length < 5) {
        newCategories.push(category);
    }
    setSelectedCategories(newCategories);
    updatePosts(newCategories);
  }
};

const updatePosts = (categories) => {
  hasFetched.current = false;
    setError(false);
    setNoPostsToLoad(false);
    setLoading(true);
    if(!categories) {
      fetch(`${config.backendIP}/post`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((newPosts) => {
        setNumArticles(newPosts.length);
        setPosts(newPosts);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
    }
    fetch(`${config.backendIP}/post?categories=${categories}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((newPosts) => {
        setNumArticles(newPosts.length);
        setPosts(newPosts);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  function handleSearch() {
    setLoading(true);
    setIsSearchActive(true);
    const encodedSearchTerm = encodeURIComponent(searchTerm);

    fetch(`${config.backendIP}/search?query=${encodedSearchTerm}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      })
      .then((newPosts) => {
        setNumArticles(newPosts.length);
        setPosts(newPosts);
      })
      .catch((error) => {
        setError(`Search failed: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  
  function resetSearch() {
    setSearchTerm('');
    setIsSearchActive(false);
    updatePosts();
  }

  return (
    <>
  <div style={{ textAlign: 'center', fontSize:"18px", marginBottom:"10px" }}>Schülerzeitung der Klaus-Groth-Schule Neumünster</div>
      {showAd && (
        <div className="newsletter-ad" onClick={() => window.location.href = '/signup'} style={{cursor:"pointer"}}>
          <div>
            <h4>Newsletter</h4>
          Erhalte regelmäßig spannende Artikel und Updates direkt per E-Mail. Melde dich hier für unseren zwei- bis vierwöchentlichen Newsletter an und bleibe auf dem Laufenden. Trage einfach deine E-Mail-Adresse ein, indem du hier klickst!
          </div>
        </div>
      )}

      {showAd && (
        <div className="sticky-div" onClick={() => window.location.href = '/signup'} style={{cursor:"pointer"}}>
          <h3>Newsletter</h3>
          Erhalte regelmäßig spannende Artikel und Updates direkt per E-Mail. Melde dich hier für unseren zwei- bis vierwöchentlichen Newsletter an und bleibe auf dem Laufenden. Trage einfach deine E-Mail-Adresse ein, indem du hier klickst!
          <button className="close-button" onClick={(e) => { e.stopPropagation(); closeAd(); }}>
            X
          </button>
        </div>
      )}

{showCookieConsent && (
        <div className="cookieConsent">
          Wir nutzen Essentielle Cookies, um eine Optimale Nutzererfahrung zu gewährleisten.
          <button onClick={closeCookieConsent} style={{borderRadius:"5px"}}>Okay</button>
        </div>
      )}
      {!maintenanceError && !error && (
        <div className="IndexPageSettings">

            <div className="horizontalThemeList">
              <button className="theme-btn-reset" onClick={() => handleCategoryClick("delete")}>Alle Themen</button>
              <button className={`theme-btn ${selectedCategories.includes("Schule und Neumünster") ? 'active' : ''}`} onClick={() => handleCategoryClick("Schule und Neumünster")}>Schule und Neumünster</button>
              <button className={`theme-btn ${selectedCategories.includes("Spicker-Tipps") ? 'active' : ''}`} onClick={() => handleCategoryClick("Spicker-Tipps")}>Spicker-Tipps</button>
              <button className={`theme-btn ${selectedCategories.includes("Politik/Gesellschaft") ? 'active' : ''}`} onClick={() => handleCategoryClick("Politik/Gesellschaft")}>Politik/Gesellschaft</button>
              <button className={`theme-btn ${selectedCategories.includes("Interessantes") ? 'active' : ''}`} onClick={() => handleCategoryClick("Interessantes")}>Interessantes</button>
              <button className={`theme-btn ${selectedCategories.includes("Redaktion") ? 'active' : ''}`} onClick={() => handleCategoryClick("Redaktion")}>Redaktion</button>
            </div>
              <div className="search-container">
                <input type="text" placeholder="Suche..." value={searchTerm} className="search-input" onChange={(e) => setSearchTerm(e.target.value)} />
                <button onClick={handleSearch} className="search-btn">Suchen</button>
             </div>  
        </div>
      )}
      <div id="loader" className={loading ? '' : 'hidden'}>
        <div className="loader-icon"></div>
        <div className="loader-text">Einen Moment...</div>
      </div>
  
      {maintenanceError && (
        <div className="IndexPageError">Die Website befindet sich derzeit in Wartungsarbeiten. Bitte versuche es später erneut.</div>
      )}
  
      {posts.length > 0 && posts.map((post, index) => (
        <div key={post._id} className="Post">
          <Post {...post} />
        </div>
      ))}

      {!maintenanceError && error && (
        <div className="IndexPageError">
          Hier sollten eigentlich Artikel angezeigt werden. Leider klappt das momentan nicht. Bitte probiere es später erneut. Du könntest dir aber die <Link to="/about">Über uns</Link> Seite anschauen.
        </div>
      )}
      {!loading && (
        <>
          {!isSearchActive && !noPostsToLoad && !maintenanceError && !error && (
            <button onClick={loadMore} className="LoadMoreBtn">Mehr Artikel</button>
          )}
          {noPostsToLoad && (
            <div className="IndexPageError">Es gibt keine weiteren Artikel.</div>
          )}
          {isSearchActive && (
            <button className="LoadMoreBtn resetButtonImportant" onClick={resetSearch} style={{backgroundColor:"red"}}>Zurücksetzen</button>
          )}
        </>
      )}
    </>
  );
          }  