import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import config from "./config";
import { LazyLoadImage } from 'react-lazy-load-image-component';



export default function Post({_id, title, summary, cover, createdAt, author, topic}) {
    const [imageError, setImageError] = useState(false); 
    const [updatedSrc, setUpdatedSrc] = useState("");

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/post/${_id}`);
    };

    const handleImageError = () => {
        setImageError(true);
    };

    useEffect(() => {
        if (!updatedSrc) {
            try {
                const filenameMatch = cover.match(/[\\/](?:.(?![\\/]))+$/);
                const coverFileName = filenameMatch ? filenameMatch[0].substr(1) : cover;
                setUpdatedSrc(`${config.backendIP}/uploads/${coverFileName}`);
            } catch {
                console.log("Img match error");
            }
        }
    }, [cover, updatedSrc]);
    

    return( 
        <div className="Article" onClick={handleClick} style={{cursor:"pointer"}}>
        <div className="post" style={imageError ? { gridTemplateColumns: '1fr' } : {}}>
            {!imageError && (
                <div className="image">
                        <LazyLoadImage
                            src={updatedSrc}
                            alt="Hier sollte ein Bild sein."
                            className="rounded-image"
                            onError={handleImageError}
                        />

                </div>
            )}

            <div className="texts" style={{marginLeft: '7px'}}>
          <Link to={`/post/${_id}`}>
        <h2>{title}</h2>
        <div>{topic}</div>
        </Link>
        <span className="info"> 
                {author && (author.username ? 
            <span className="author" href="tertert" target="_blank" rel="noreferrer">{author.username}</span>
            : <span className="author">{author}</span>)
        }

          <span>{new Date(createdAt).toLocaleDateString()}</span>

        </span>
        <p className="summary">{summary}</p>
        </div>
        </div>
    </div>
    );

}

