import { Bar } from 'react-chartjs-2';
import { Chart, BarController, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Tooltip } from 'chart.js';
Chart.register(Tooltip);
Chart.register(BarController, BarElement, CategoryScale, LinearScale);
Chart.defaults.color = '#36A2EB';
Chart.defaults.borderColor = '#36A2EB';
const BarChart = ({ topPosts }) => {
    if (!topPosts.length) {
        return <p>Keine Daten verfügbar</p>;
    }
    
    const data = {
        labels: topPosts.map(entry => entry.postId), // Verwendung von postId statt entry[0]
        datasets: [{
            label: 'Anzahl der Klicks',
            data: topPosts.map(entry => entry.clicks), // Verwendung von clicks statt entry[1]
            backgroundColor: 'rgba(255, 99, 132, 0.3)', // Farbe angepasst
            borderColor: 'rgba(255, 99, 132, 1)', // Farbe angepasst
            borderWidth: 1
        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return <Bar data={data} options={options} />;
};

export default BarChart;
