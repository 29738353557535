import DOMPurify from 'dompurify';
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import config from "../config"
import Editor from "./tools/Editor/Editor";
import "./css/C-EP.css"



export default function EditPost() {
    const {id} = useParams();
    const [title,setTitle] = useState('');
    const [summary,setSummary] = useState('');
    const [content,setContent] = useState('');
    const [files, setFiles] = useState('');
    const [topic, setTopic] = useState('');
    const [author, setAuthor] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useState(() => {
        fetch(config.backendIP + '/post/' + id)
        .then(response => {
            response.json().then(postInfo => {
                postInfo.content = DOMPurify.sanitize(postInfo.content);
                setTitle(postInfo.title);
                setContent(postInfo.content);
                setSummary(postInfo.summary);
                setTopic(postInfo.topic);
                setAuthor(postInfo.author);
                setIsLoading(false);
                console.log(postInfo);
            })
            if (response.status === 505) {
                    alert("Die Website befindet sich momentan in Wartungsarbeiten. Bitte probiere es später erneut.")
                    setRedirect(true)
            }
        })
    }, [id]);
    
    async function UpdatePost(ev) {
        ev.preventDefault()
        const data = new FormData();
        data.set('title', title);
        data.set('summary', summary);
        data.set('content', content);
        data.set('id', id);
        data.set('topic', topic);
        if(files?.[0]){
            data.append('file', files[0]);
        }
        data.append('textAuthor', author);
    
        setIsLoading(true);
    
        const response = await fetch(config.backendIP + "/post", {
            method: 'PUT',
            body: data,
            credentials: 'include',
        })
        if (response.ok){
            alert("Änderungen gespeichert.");
            setRedirect(true);
        }else if (response.status === 505) {
            alert("Die Website befindet sich momentan in Wartungsarbeiten. Bitte probiere es später erneut.")
            setIsLoading(false)
        }else {
            alert("Etwas ist schiefgegangen.")
        }
    }
    
    if (redirect) {
        return <Navigate to={`/post/${id}`} />
    }
    
    return (
        <>
        
        <div className="Info">Der Editor hat ein Update erhalten! Videos einfügen funktioniert nicht, weil ich sonnst eine Sicherheitsfunktion deaktivieren muss. Ich guck nochmal, ob ich das trotzdem hinbekomme.</div>
        

            {isLoading && <div>Erhalte Informationen, bitte warten...</div>}
            {!isLoading && (
                <form onSubmit={UpdatePost}>
                    <input type="title" placeholder={'Titel'} value={title} onChange={ev => setTitle(ev.target.value)} required />
                    <input type="summary" placeholder={'Beschreibung'} value={summary} onChange={ev => setSummary(ev.target.value)} required/>
                    <input type="file" accept="image/*" onChange={ev => {
                        const file = ev.target.files[0];
                        try {
                        const fileSize = file.size / 1024 / 1024; // size in MB
                        const maxSize = config.maxFileSizeInMB; // maximum size in MB
                        if (fileSize > maxSize) {
                            alert(`Die ausgewählte Datei ist zu groß. Die maximale Dateigröße beträgt ${maxSize} MB.`);
                            ev.target.value = null; // clear file input
                        } else {
                            setFiles(ev.target.files);
                            console.log(files)
                        }
                    }
                    catch {
                        console.log("error while measuring file size.")
                    }
                    }} />
                            <select className='selector' value={topic} onChange={ev => setTopic(ev.target.value)} required>
                            <option value="">Thema wählen</option>
                            <option value="Schule und Neumünster">Schule und Neumünster</option>
                            <option value="Spicker-Tipps">Spicker-Tipps</option>
                            <option value="Politik/Gesellschaft">Politik/Gesellschaft</option>
                            <option value="Interessantes">Interessantes</option>
                            <option value="Redaktion">Redaktion</option>
                            </select>
                            <Editor value={content} onChange={setContent} />
                    <input type='author' placeholder='Autor' value={author} onChange={ev => setAuthor(ev.target.value)} />
                    <button style={{marginTop:'5px'}}>Artikel ändern</button>
                </form>
            )}
        </>
    );
}    