import React, { useState, useEffect, useRef, useCallback } from 'react';
import config, { backendIP } from "../config"
import "./css/AP.css"
import Popup from './tools/Popup/Popup';
import RequestChart from './tools/RequestChart/RequestChart';
import BarChart from './tools/PostChart/PostChat'


export default function AdminPanel() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState('');
  const [email, /*setEmail*/] = useState('');
  const hasFetched = useRef(false)
  const hasFetched2 = useRef(false)
  const [datacount, setDatacount] = useState();
  const [datatotal, setDatatotal] = useState();
  const [emailSending, setEmailSending] = useState(false);
  const [progressStarted, setProgressStarted] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [postClickCounts, setPostClickCounts] = useState([]);
  const [allPermissions] = useState(["deleteOtherPosts","editOtherPosts" , "setBan", "setAdmin", "setPermissions" ,"setMaintenance","sendEmails"]);
  const [showPermissions, setShowPermissions] = useState(true);
  function fetchPosts() {
    fetch(`${config.backendIP}/post`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: "include",
    })
      .then(response => response.json())
      .then(data => {
        setPosts(data.map(post => ({ id: post._id, title: post.title })));
      })
      .catch(error => {
        setError(true);
      });
}
  function sendPostByEmail() {
    fetch(`${config.backendIP}/email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: "include",
      body: JSON.stringify({ postId: selectedPost, email })
    })
      .then(response => {
        setEmailSending(true)
        setProgressStarted(true)
        if (!response.ok) {
          setEmailSending(false)
          setProgressStarted(false)
          throw new Error('Failed to send post by email');
        }
      })
      .catch(error => {
        setError(true);
      });
  }
  

  function toggleBanStatus(userId, isBanned) {
     
      fetch(`${config.backendIP}/adminpanelUsers/${userId}/toggleban`, {
        method: 'PUT',
        body: JSON.stringify({ isBanned }),
        headers: { 'Content-Type': 'application/json' },
        credentials: "include",
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to toggle ban status');
          }
          hasFetched.current = false
          hasFetched2.current = false
          return response.json();
          
        })
        .then(data => {
          fetchUsers();
        })
        .catch(error => {
          setError(true);
        });
  }
  
  function toggleAdminStatus(userId, isAdmin) {
      fetch(`${config.backendIP}/adminpanelUsers/${userId}/toggleadmin`, {
        method: 'PUT',
        body: JSON.stringify({ isAdmin }),
        headers: { 'Content-Type': 'application/json' },
        credentials: "include",
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to toggle admin status');
          }
          hasFetched.current = false
          hasFetched2.current = false
          return response.json();
        })
        .then(data => {
          fetchUsers();
        })
        .catch(error => {
          setError(true);
        });
  }
  
  const fetchUsers = useCallback(() => {
    if(!hasFetched.current) {
      hasFetched.current = true
    fetch(config.backendIP + "/adminpanelUsers", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",

    })
      .then((response) => {
        if(response.status !== 418) {
        return response.json()
        }
        else {
          setError(true)
        }
      })
      .then(data => setUsers(data))
      .catch(error => {
        setError(true);
      });
    }

  }, []);

  const handleSliderChange = () => {
    const newMaintenanceMode = !maintenanceMode;
    setMaintenanceMode(newMaintenanceMode);

    fetch(`${config.backendIP}/maintenance`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ postRequestsDisabled: newMaintenanceMode }),
      credentials: "include"
    }).then((response) => {
      // Handle response
    });
  };
  
  const [isFetching2, setIsFetching2] = useState(false);
  
  function fetchMaintenance() {
    if (isFetching2) return;
    setIsFetching2(true);
      fetch(`${config.backendIP}/maintenance/info`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      })
        .then(response => response.json())
        .then(data => {
          setMaintenanceMode(data.maintenanceMode);
        })
        .catch(error => {
          setError(true);
        });
  }

function fetchRequestData(daycount) {

  fetch(`${config.backendIP}/requestData?days=${daycount ?  daycount : '1'}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: "include",
  })
  .then(response => response.json())
  .then(data => {
    setRequestData(data);
  })
  .catch(error => {
    console.log(error)
    setError(true);
  });
}

const [isFetching, setIsFetching] = useState(false);

function fetchPostClickCounts() {
  if (isFetching) return;

  setIsFetching(true);
  fetch(`${config.backendIP}/topPosts`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: "include",
  })
  .then(response => response.json())
  .then(data => {
    setPostClickCounts(data);
  })
  .catch(error => {
    console.log(error)
    setError(true);
  });
}


useEffect(() => {
  if (!hasFetched.current) {
    fetchUsers();
    fetchPosts();
    fetchMaintenance();
    fetchRequestData();
    fetchPostClickCounts();

    hasFetched.current = true;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


  

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if(emailSending) {
    const intervalId = setInterval(() => {
      fetch(backendIP + '/emailProgress', {
        credentials: "include"
      })
        .then(response => response.json())
        .then(data => {
          const datacount = data.count;
          setDatacount(datacount);
          const datatotal = data.total;
          setDatatotal(datatotal);
          const newProgress = (data.count / data.total * 100).toFixed(1);
          setProgress(newProgress);
          if(progress >= 100) {
            setEmailSending(false)
          }
        });
    }, 200);
    
    return () => clearInterval(intervalId);
  }}, [emailSending, progress]); 
  
  function getPermissionsArray(permissions) {
    if (!permissions) return [];
    return Object.keys(permissions).filter(perm => permissions[perm]);
}


  
  const handleAddPermission = (userId, permission) => {
    fetch(`${config.backendIP}/adminpanelUsers/${userId}/addPermission`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: "include",
      body: JSON.stringify({ permission })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to add permission');
        }
        hasFetched.current = false;
        fetchUsers();
      })
      .catch(error => {
        setError(true);
      });
  }
  
  const handleRemovePermission = (userId, permission) => {
    fetch(`${config.backendIP}/adminpanelUsers/${userId}/removePermission`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: "include",
      body: JSON.stringify({ permission })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to remove permission');
        }
        hasFetched.current = false;
        fetchUsers();
      })
      .catch(error => {
        setError(true);
      });
  }

let userPermissionsArray;
let availablePermissionsForEachUser = [];

if (users && users.length > 0) {
  const uniquePermissions = [...new Set(allPermissions)];

  availablePermissionsForEachUser = users.map(user => {
      userPermissionsArray = getPermissionsArray(user.permissions);
      const availablePermissionsArray = uniquePermissions.filter(perm => !userPermissionsArray.includes(perm));
      return availablePermissionsArray;
  });
}


function DelClickCounts() {
  fetch(config.backendIP + "/delTopPosts", {
    method: "DELETE",
    credentials: "include"
  })
  .then(response => {
    if(response.ok) {
      alert("Daten gelöscht");
      hasFetched.current = false;
      setIsFetching(false)
      fetchPostClickCounts()
      setIsFetching(true)
      hasFetched.current = true;
    } else {
      alert("Daten nicht gelöscht. Es gab einen Fehler.");
    }
  })
  .catch(error => {
    console.error("Fehler bei der Anfrage:", error);
    alert("Ein Fehler ist aufgetreten.");
  });
}
  
  return (
    <div>
      <div>
      </div>
        <div className="email-post-container" style={{ display: maintenanceMode ? 'none' : 'block' }}>
    <h2>Beitrag per E-Mail versenden</h2>
  <div className="select-container">
    <select value={selectedPost} onChange={e => setSelectedPost(e.target.value)}>
      <option value="">Wähle einen Beitrag</option>
      {posts.map(post => <option key={post.id} value={post.id}>{post.title}</option>)}
    </select>
  </div>
  <button onClick={sendPostByEmail}>Senden</button>
    {progressStarted &&
    <div className='div1'>
      <div className='div2' style={{  width: `${progress}%` }}></div>
      <div className='div3'>
        {progress}% ({datacount} / {datatotal})
      </div>
    </div>
}
  </div>
    <div className="admin-panel-container">
      {error && (
        <Popup
          title="Admin login required"
          message="You must be logged in as an admin to access this page."
          buttonText="Go to login Page"
          buttonLink="/adminlogin"
          closeButton={true}
        />
      )}
      <div>
        <h1>Settings</h1>
        <label className="setting">
          Maintenance mode:
          <label className="switch">
          <input type="checkbox" onChange={handleSliderChange} checked={maintenanceMode ? "checked" : ""} />

            <span className="slider round"></span>
          </label>
        </label>
      </div>
      <div className="admin-panel-content" style={{ display: maintenanceMode ? 'none' : 'block' }}>
  <h1>Users</h1>
  <button onClick={() => setShowPermissions(!showPermissions)} style={{padding: "5px",margin:"5px", backgroundColor:"#11cc33", color:"black", borderRadius:"5px", cursor:"pointer" }}>Berechtigungen</button>
  <table>
    <thead>
      <tr key={"test"}>
        <th>Name</th>
        <th style={{padding:"0 10px"}}>Adminstatus</th>
        <th style={{padding:"0 10px"}}>Sperrstatus</th>
        <th style={{padding:"0 10px", borderLeft:"2px solid #000", borderBottom:"2px solid #000", borderTop:"2px solid #000", display: showPermissions ? "none" : ""}}>Remove Permissions</th>
        <th style={{padding:"0 10px", borderBottom:"2px solid #000", borderTop:"2px solid #000", borderRight:"2px solid #000", display: showPermissions ? "none" : ""}}>Add Permissions</th>
        <th style={{padding:"0 10px"}}>Status Controls</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(users) && users.map((user, index) => (
        <tr key={user._id}>
          <td>{user.username}</td>
          <td>{user.isAdmin ? "Ja" : "Nein"}</td>
          <td>{user.isBanned ? "Gesperrt" : "Nicht gesperrt"}</td>
          <td style={{padding:"0 10px", borderLeft:"2px solid #000", borderRight:"2px solid #000", borderBottom:"2px solid #000", borderTop:"2px solid #000", display: showPermissions ? "none" : ""}}>
            {Array.isArray(getPermissionsArray(user.permissions)) && getPermissionsArray(user.permissions).map(perm => (
              <span 
                className="permission-item" 
                key={perm} 
                onClick={() => handleRemovePermission(user._id, perm)}
              >
                {perm}
              </span>
            ))}
          </td>
          <td style={{padding:"0 10px", borderLeft:"2px solid #000", borderRight:"2px solid #000", borderBottom:"2px solid #000", borderTop:"2px solid #000", display: showPermissions ? "none" : ""}}>
            {Array.isArray(availablePermissionsForEachUser[index]) && availablePermissionsForEachUser[index].map(perm => (
              <span 
                className="permission-item add" 
                key={perm} 
                onClick={() => handleAddPermission(user._id, perm)}
              >
                {perm}
              </span>
            ))}
          </td>
          <td>
            <button className="ban-btn" onClick={() => toggleBanStatus(user._id, user.isBanned)}>
              {user.isBanned ? 'unban' : 'ban'}
            </button>
            <button className="admin-btn" onClick={() => toggleAdminStatus(user._id, user.isAdmin)}>
              {user.isAdmin ? 'remove admin' : 'Make admin'}
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

        <div style={{maxWidth:"1000px", height:"800px"}}>
          <h2>Anfragen an den Server (Backend) (inklusive bilder)</h2>
         {!error && <div className="button-container">
              <button className="day-button" id="oneDay" onClick={(() => {fetchRequestData(1)})}>1 Tag</button>
              <button className="day-button" id="oneDay" onClick={(() => {fetchRequestData(7)})}>7 Tage</button>
              <button className="day-button" id="sevenDays"onClick={(() => {fetchRequestData(31)})}>1 Monat</button>
    </div>}
        <RequestChart requestData={requestData} />
        <h2>Am meisten aufgerufene Artikel</h2>
        <button onClick={DelClickCounts}>Löschen</button>
        <BarChart topPosts={postClickCounts}/>
        </div>
        </div>
    </div>
  );
}