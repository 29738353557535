import React, { useState } from 'react';
import { useCookies } from "react-cookie"
import './css/newsletter.css'
import config from '../config';

function NewsletterSignup() {
  const [email, setEmail] = useState('');
  const [cookie, setCookie] = useCookies(['hideAd']);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch(config.backendIP + '/signup', {
      method: 'POST',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    alert(data);

    if (data === `Eine E-Mail mit einem Bestätigungscode wurde an ${email} geschickt. Bitte geben sie diesen Code hier ein.`) {
      window.location.href = '/signup/verify';
    } else {
      setCookie('hideAd', true, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
    }
  };

  return (
    <>
      <div className="content">
      </div>
      <div className='newsletter-description'>
        <h1>Ab sofort keinen Artikel verpassen!</h1>
        <p>Registriere dich für unseren Newsletter und erhalte alle zwei bis vier Wochen eine Zusammenfassung neuer, lesenswerter Artikel direkt in dein Postfach.</p>
      </div>
      <div className="newsletter-container">
        <div className="gradient-background"></div>
        <p>Trage dazu einfach deine E-Mail-Adresse hier ein:</p>
        <form className="newsletter-form" onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="E-Mail Adresse"
          />
          <button type="submit">Abonnieren</button>
        </form>
      </div>
      <div className="unsubscribe-section">
        <h1>Wenn es dir doch zu viel wird…</h1>
        <p>…kannst du dein Newsletter-Abo jederzeit unkompliziert über den Link unten in den E-Mails beenden und erhältst keine Nachrichten mehr.</p>
      </div>
    </>
  );
}

export default NewsletterSignup;
