import React from "react";
import { Link } from "react-router-dom";
import "./css/404P.css"
export default function NotFoundPage() {
  return (
    <div>
      <div className="error-container">
        <h1 className="error-code">404</h1>
        <div className="error-message">
          <h2>Das konnte nicht gefunden werden.</h2>
          <Link to="/" className="LinkToStartPage">Startseite</Link>
        </div>
      </div>
    </div>
  );
}
