import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../config"

export default function SignoutPage() {
    const [emailToSignout, setEmailToSignout] = useState("");
    const [confirm, setConfirm] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const email = query.get('signoutmail');
        if (email) {
            setEmailToSignout(email);
        }
    }, [location]);

    const handleSignout = async () => {
        if (confirm) {
            console.log(JSON.stringify(emailToSignout))
            try {
                const response = await fetch(config.backendIP + '/signout', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ emailToSignout }),
                    credentials: "include",
                });
                if (response.status === 500){
                    alert("Bitte versuche es erneut. Es ist ein Fehler aufgetreten.")
                }
                else if (response.status === 200) {
                    alert("Du bist nun aus unserem Newsletter entfernt. Du solltest eine letzte Bestätigungsemail bekommen haben.")
                }
                else if (response.status === 405) {
                    alert("Diese Email ist nicht in unserem Newslettersystem.")
                }

            }
            catch {
                console.error("Could not sign out of Newsletter")
            }
        }
    };
    

    const handleCancel = () => {
        setConfirm(false);
        navigate("/")
    }

    return (
        <div style={styles.container}>
            <h1>Newsletter Abmeldung</h1>
            {emailToSignout && (
                <div>
                    <h3>Möchtest du dich wirklich vom Newsletter abmelden und keine weiteren E-Mails an {emailToSignout} erhalten?</h3>
                    <div style={styles.buttonContainer}>
                        <button style={styles.signoutButton} onClick={() => {
                            setConfirm(true);
                            handleSignout();
                        }}>Ja, abmelden</button>
                        <button style={styles.cancelButton} onClick={() => handleCancel() }>Nein, abbrechen</button>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        textAlign: 'center',
        padding: '20px'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    signoutButton: {
        backgroundColor: 'red',
        color: 'white',
        padding: '10px 20px',
        margin: '0 10px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    },
    cancelButton: {
        backgroundColor: 'green',
        color: 'white',
        padding: '10px 20px',
        margin: '0 10px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    }
};
