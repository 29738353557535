import React from "react";
import AboutUs from "../aboutUs";

const AboutPage = () => {
return (
<div>
<AboutUs />
</div>
);
};

export default AboutPage