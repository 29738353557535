import 'react-quill/dist/quill.snow.css';
import { useState } from "react";
import { Navigate } from "react-router-dom";
import Editor from "./tools/Editor/Editor";
import "./css/C-EP.css"
const config = require('../config');

export default function CreatePost() {
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('<p>Schreibe hier...</p>');
  const [files, setFiles] = useState('');
  const [topic, setTopic] = useState('');
  const [author, setAuthor] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [articleType, setArticleType] = useState('text');


  async function createNewPost(ev) {
    console.log(articleType)
    console.log(files.length, files)
    ev.preventDefault();
    if (articleType === 'text') {
    if (title.trim() === '' || summary.trim() === '' || content.trim() === '' || topic.trim() === '') {
      alert("Bitte fülle alle Felder aus.");
      return;
    }
  }
  if (articleType === 'odt' && files.length === 0) {
    alert('Wenn du einen Artikel mit der odt funktion erstellen willst, musst du auch eine .odt datei hochladen.')
    return;
  }
    const formData = new FormData();
    formData.append('type', articleType);
    formData.append('title', title);
    formData.append('summary', summary);
    if(articleType === 'text') {
      formData.append('content', content);
    }
    formData.append('file', files[0]);
    formData.append('topic', topic);
    formData.append('textAuthor', author);

    const response = await fetch(config.backendIP + '/post', {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    if (response.ok) {
      setRedirect(true);
      alert("Artikel veröffentlicht.")
    } else if (response.status === 400) {
      alert("Bitte fülle alle Felder aus. Wenn es trotzdem nicht klappt, probiere es später erneut.");
    } else if (response.status === 401) {
      alert("Du bist nicht angemeldet. Bitte melde dich erneut an.");
    } else if (response.status === 402) {
      alert("Bitte lade nur Bilddateien hoch.");
    }
    else if (response.status === 505) {
      alert("Die Website befindet sich gerade in Wartungsarbeiten. Bitte probiere es später erneut.")
    } else if (response.status === 500) {
      alert("Ein Fehler ist aufgetreten. Bitte kontroliere, ob die Bilder zu groß zum hochladen sind.")
    }
  }


  if (redirect) {
    return <Navigate to={'/'} />
  }

  return (
    <>
      <h1>Artikel erstellen</h1>
      <div className='IndexPageError'>Aktuell besteht ein Problem, das es unmöglich macht, Bilder in Artikeln zu veröffentlichen.</div>
      <form onSubmit={createNewPost}>
      <div className="article-type-container">
        <button
          onClick={() => setArticleType('text')}
          className={`article-type-button ${articleType === 'text' ? 'active' : ''}`}
        >
          Text
        </button>
        <button
          onClick={() => setArticleType('odt')}
          className={`article-type-button ${articleType === 'odt' ? 'active' : ''}`}
        >
          ODT Datei
        </button>
        </div>
        <input type="title" placeholder={'Titel'} value={title} onChange={ev => setTitle(ev.target.value)} required />
            <input type="summary" placeholder={'Beschreibung'} value={summary} onChange={ev => setSummary(ev.target.value)} required />
            <p>{articleType === 'text' ? 'Cover bild hinzufügen (Optional)' : '.odt datei hochladen:' }</p>
            <input type="file" 
          accept={articleType === 'text' ? "image/*" : "application/vnd.oasis.opendocument.text"}
          onChange={ev => {
            const file = ev.target.files[0];
            const fileSize = file.size / 1024 / 1024;
            const maxSize = config.maxFileSizeInMB;
            if (fileSize > maxSize) {
              alert(`Die ausgewählte Datei ist zu groß. Die maximale Dateigröße beträgt ${maxSize} MB.`);
              ev.target.value = null;
            } else {
              setFiles(ev.target.files);
            }
          }}
        />


        {articleType === 'text' && (
          <>
        <Editor value={content} onChange={setContent} />
          </>
        )}
          <select className='selector' value={topic} onChange={ev => setTopic(ev.target.value)} required>
            <option value="">Thema wählen</option>
            <option value="Schule und Neumünster">Schule und Neumünster</option>
            <option value="Spicker-Tipps">Spicker-Tipps</option>
            <option value="Politik/Gesellschaft">Politik/Gesellschaft</option>
            <option value="Interessantes">Interessantes</option>
            <option value="Redaktion">Redaktion</option>
          </select>
        <input type='author' placeholder='Autor' value={author} onChange={ev => setAuthor(ev.target.value)} />
        <button style={{ marginTop: '5px' }}>Artikel veröffentlichen</button>
      </form>
    </>
  );

}