import React, { useState } from 'react';
import config from '../config';
import { useCookies } from 'react-cookie';
import './css/newsletter_verify.css'

function NewsletterVerify() {
  const [code, setCode] = useState('');
  const [cookie, setCookie] = useCookies(['hideAd']);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch(config.backendIP + '/signup/verify', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });
    const data = await response.json();
    alert(data)
    setCookie('hideAd', true, { expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) });
  };

  return (
    <div className="newsletter-verify">
      <h2 className="newsletter-title">Email-Verifizierung</h2>
      <h5 className="newsletter-instruction">Bitte geben Sie den in der Email erhaltenen Code ein.</h5>
      <form onSubmit={handleSubmit} className="newsletter-form">
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
          className="newsletter-input"
        />
        <button type="submit" className="newsletter-submit-button">Verifizieren</button>
      </form>
    </div>
  );
}

export default NewsletterVerify;
