import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineController, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';
import { Tooltip } from 'chart.js';
Chart.register(Tooltip);

Chart.register(LineController, LineElement, PointElement, CategoryScale, LinearScale);

Chart.defaults.color = '#36A2EB';
Chart.defaults.borderColor = '#36A2EB';
const RequestChart = ({ requestData }) => {
    // Überprüfen Sie, ob requestData ein Objekt ist und nicht null
    if (typeof requestData !== 'object' || requestData === null || requestData.length === 0) {
        return <p>Daten nicht verfügbar</p>;
    }
    // requestData in ein Array von Objekten konvertieren
    const formattedData = Object.entries(requestData).map(([timestamp, count]) => ({ timestamp, count }));
    const data = {
        labels: formattedData.map(request => {
            const dateObj = new Date(request.timestamp.replace(/\./g, ":"));
            const dayMonth = dateObj.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit' });
            const time = dateObj.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit', second: undefined });
            return `${dayMonth}  ${time}`;
        }),

        datasets: [{
            label: 'Anfragen (Monotone Interpolation)',
            data: formattedData.map(request => request.count),
            borderColor: 'rgb(255, 99, 132)',
            fill: false,
            cubicInterpolationMode: 'monotone',
            tension: 0.4
        } //{
           // label: 'Anfragen (Standard Interpolation)',
           // data: formattedData.map(request => request.count),
           // borderColor: 'rgb(54, 162, 235)',
           // fill: false,
           // tension: 0.4
        ]
    };

    const options = {
        scales: {
            x: {
                type: 'category',
                title: {
                    display: true,
                    text: 'Zeitpunkt'
                }
            },
            y: {
                type: 'linear',
                title: {
                    display: true,
                    text: 'Anzahl der Anfragen'
                }
            }
        },
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    title: function(tooltipItems) {
                        const tooltipItem = tooltipItems[0];
                        return tooltipItem.label;
                    },
                    label: function(tooltipItem) {
                        return 'Anfragen: ' + tooltipItem.formattedValue;
                    }
                }
                
            }
        }
    };
    

    return <Line data={data} options={options} />;
};

export default RequestChart;
