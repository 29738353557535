import { useState } from "react";
const config = require('../config');


export default function RegisterPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [adminpassword, setAdminpassword] = useState("");
  const [error, setError] = useState(null);

  async function register(ev) {
    ev.preventDefault();

    try {
      const response = await fetch(config.backendIP +'/register', {
        method: "POST",
        body: JSON.stringify({ username, password, adminpassword}),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        alert('Account erstellt.')
      } else {
        if (response.status === 400) {
          alert("Admin Password wrong")
        } else if (response.status === 401) {
          alert("Der Benutzername wird schon verwendet.")
        }
      }
    } catch (error) {
      console.error("API request failed:", error);
      setError("Es gab ein Problem bei der Anfrage.");
    }
  }

  return (
    <>
      {error && <div className="IndexPageError">Leider gibt es keine antwort vom Server. Probiere es später erneut oder sende uns eine E-Mail:</div>}
      <form className="register" onSubmit={register}>
        <h1>Registrieren</h1>
        <input type="text" placeholder="Benutzername" required value={username} onChange={(ev) => setUsername(ev.target.value)} />
        <input type="password" placeholder="Passwort" required value={password} onChange={(ev) => setPassword(ev.target.value)} />
        <input type="text" placeholder="Admin Passwort" required value={adminpassword} onChange={(ev) => setAdminpassword(ev.target.value)} />
        <button>Registrieren</button>
      </form>
    </>
  );
}
