import {React, useState} from "react";
import { Link } from "react-router-dom";
import Luk from "./aboutUsPictures/Luk.jpg"
import Charlotte from "./aboutUsPictures/Charlotte.jpg"
import Emma from "./aboutUsPictures/emma.jpg"
import "./pages/css/AboutP.css"
const AboutUs = () => {

  const [showFullStory, setShowFullStory] = useState(false);
  const text = (
    <span>
Der Spicker hat sich 2019 auf Initiative von Luk Nickel, Florian Conrad (damals 7. Klasse) und Jonas Wisper (damals 6. Klasse) gegründet. Nachdem am 18. September in der Pause ein Interessenten-Treffen mit 30-40 Personen stattgefunden hatte, traf sich das verbliebene Team fortan wöchentlich am Nachmittag, gab sich den Namen “Der Spicker” und veröffentlichte am 7. Dezember 2019 schließlich eine erste kleine Ausgabe als PDF auf der Schulhomepage.
    </span>
  );

  const text2 = (
    <span>
      Schon damals war Herr Gerriets als unser Beratungslehrer unterstützend dabei. Die zweite Ausgabe sollte Anfang 2020 erscheinen. Corona kam. Es begannen mit 2020 und 2021 zwei für uns sehr chaotische und unbefriedigende Jahre, in denen wir uns immer wieder neu ausprobierten, ohne wirklich Fuß zu fassen. Wir hatten die ganze Zeit über unseren Instagram-Account @spicker_schuelerzeitung, dazu zeitweise eine eigene Website (mit einem Baukasten erstellt), veröffentlichten Artikel als Dokumente über einen Linktree und hatten sogar ein eigenes Radio-Programm beim Freien Radio Neumünster. Wenn du mehr über diese Geschichte erfahren willst, gibt es einen ausführlichen Artikel aus 2022 dazu hier. [Link zu Reupload des besagten Artikels, lade ich später hoch und sage dir Bescheid] Anfang des Jahres 2022 kam dann die Entscheidung: Wir wollen endlich gedruckte Ausgaben! Die erste kam am 1. April, die zweite am 1. Juli, gefolgt vom 8. Februar 2023, dem 14. Juli 2023 und dem 20. Dezember 2023. Die Auflagen lagen zwischen 200 und 400 Exemplaren und weil wir an den Druckern der Schule drucken, ist der Spicker vollkommen kostenlos. Die letzten beiden Jahre waren für uns extrem motivierend und wir freuen uns auf die Zukunft!
      <h3>Aktuelle Situation</h3>
      Gerade arbeiten wir in unserem Team{" "}
      <Link to="/helpUs">(Du willst ein teil des Teams werden? Klicke hier!)</Link> an der sechsten Ausgabe, die natürlich wieder kostenlos sein wird. Mit Gunnar Musan vom Verein der Ehemaligen arbeiten wir gerade noch daran, die kostenlose Design-App “Canva” für unser Layout durch das komplexere Programm “Affinity Publisher 2” zu ersetzen und haben dafür ein kleines Design-Team gebildet, in dem wir den Umgang mit dem Programm gemeinsam lernen. Wenn wir soweit sind, kommt wieder eine gedruckte Ausgabe.
 Über die Ausgaben hinaus haben wir weiterhin unseren Instagram-Account @spicker.kgs mit mittlerweile über 370 Followern. Hier machen wir auf neue Artikel aufmerksam, veröffentlichen Info-Posts zum Schulleben und informieren in unseren Stories über aktuell wichtige Themen. Einen weiteren Überblick gibt es auf unserem Brett am Treppenaufgang in der Aula. Neuerdings gibt es auch unser Spicker-Video-Team für unterhaltsame und informative Reels.
Drittens schließlich kam Anfang Mai 2024 diese Website hinzu! Das gibt uns die Möglichkeit, viel flexibler als vorher unsere Artikel zu schreiben und zu veröffentlichen und befreit uns vom lähmenden Warten auf die nächste Ausgabe und den eingeschränkten Möglichkeiten auf Instagram. Du willst auch mal einen Beitrag für die Seite schreiben? Kein Problem!

    </span>
  );

  const handleToggleStory = () => {
    setShowFullStory((prev) => !prev);
  };
  

  return (
    <div className="AboutUs-container">
      <h1 className="AboutUs-title">Über uns</h1>
      <p className="AboutUs-desc">
      Herzlich willkommen auf der Website des Spickers! Seit 2019 versuchen wir, die Schülerinnen und Schüler der KGS bestmöglich zu informieren und zu unterhalten. Im Folgenden wollen wir dir unsere Geschichte, die Redaktion und aktuelle Pläne vorstellen. Unten findest du außerdem Kontaktinfos. 
      <h4>Wenn du an unserem Projekt mitwirken möchtest, schau doch mal <Link to="/helpUs">hier</Link> vorbei.</h4>
      </p>
      <div className={`AboutUs-storyDesc ${showFullStory ? "full" : "short"}`}>
        <h3>Die Geschichte des Spickers</h3>
        <p>
          <span
            style={{
              display: "inline-block",
              overflow: "hidden",
              maxHeight: showFullStory ? "2000px" : "125px",
              transition: "max-height 1s ease-in-out",
            }}
          >
            {text}
            {showFullStory && text2}
          </span>
        </p>
        <button
          onClick={handleToggleStory}
          style={{ borderRadius: "5px", margin: "0 auto" }}
        >
          {showFullStory ? "Weniger anzeigen" : "Mehr anzeigen"}
        </button>
      </div>


      <h2 className="AboutUs-teamTitle">Die Redaktion</h2>
      <div className="AboutUs-teamMembers">
        <div className="AboutUs-teamMember">
          <img
            src={Luk}
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Luk Nickel</h3>
          <p className="AboutUs-teamMemberDesc">Chefredakteur</p>
        </div>

        <div className="AboutUs-teamMember">
        <img
            src={Charlotte}
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Charlotte Wiehoff</h3>
          <p className="AboutUs-teamMemberDesc">Chefredakteurin</p>
        </div>

        <div className="AboutUs-teamMember">
          <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Louis Rost</h3>
          <p className="AboutUs-teamMemberDesc">Web-Architekt</p>
        </div>

      </div>
      
      <div className="AboutUs-teamMembers">
        <div className="AboutUs-teamMember">
          <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Herr Gerriets</h3>
          <p className="AboutUs-teamMemberDesc">Beratung</p>
        </div>

        <div className="AboutUs-teamMember">
        <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Hanna Neumann</h3>
          <p className="AboutUs-teamMemberDesc">Layout-Chefin</p>
        </div>

        <div className="AboutUs-teamMember">
        <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Paul Kalies</h3>
          <p className="AboutUs-teamMemberDesc">Video-Chef</p>

        </div>

      </div>
      <div className="AboutUs-teamMembers">
        <div className="AboutUs-teamMember">
          <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Lars Bülck</h3>
          <p className="AboutUs-teamMemberDesc">Redakteur</p>
        </div>

        <div className="AboutUs-teamMember">
          <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Amelina von Berg</h3>
          <p className="AboutUs-teamMemberDesc">Redakteurin</p>
        </div>

        <div className="AboutUs-teamMember">
          <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Maurice Wendt</h3>
          <p className="AboutUs-teamMemberDesc">Redakteur</p>
        </div>

      </div>
      <div className="AboutUs-teamMembers">
        <div className="AboutUs-teamMember">
          <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Florian Conrad</h3>
          <p className="AboutUs-teamMemberDesc">Redakteur</p>
        </div>

        <div className="AboutUs-teamMember">
        <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Helene Wiehoff</h3>
          <p className="AboutUs-teamMemberDesc">Redakteurin</p>
        </div>

        <div className="AboutUs-teamMember">
          <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Vincent Stricker</h3>
          <p className="AboutUs-teamMemberDesc">Redakteur</p>
        </div>

      </div>

      <div className="AboutUs-teamMembers">
        <div className="AboutUs-teamMember">
          <img
            src={Emma}
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Emma Schornstein</h3>
          <p className="AboutUs-teamMemberDesc">Redakteurin</p>
        </div>

        <div className="AboutUs-teamMember">
          <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Emma Bartels</h3>
          <p className="AboutUs-teamMemberDesc">Redakteurin</p>
        </div>

        <div className="AboutUs-teamMember">
          <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Luisa Schütt</h3>
          <p className="AboutUs-teamMemberDesc">Redakteurin</p>
        </div>

      </div>
      
      <div className="AboutUs-teamMember">
          <img
            src="https://placehold.co/150x150/png"
            alt="Teammitglied"
            className="AboutUs-teamMemberImg"
          />
          <h3 className="AboutUs-teamMemberName">Rike Kretschmann</h3>
          <p className="AboutUs-teamMemberDesc">Redakteurin</p>
        </div>

      <h2 className="AboutUs-teamTitle">Kontakt</h2>
      <p className="AboutUs-desc">
  Wir freuen uns immer über dein Feedback, Fragen zu unseren Artikeln, zur Website und zur Redaktion, Anregungen für die Zukunft und vieles mehr. Auch wenn du möglicherweise Interesse hast, im Bereich Layout, Website oder Artikel mitzuwirken, melde dich gerne.
  Dazu hast du folgende Möglichkeiten:
</p>
<ul>
  <li className="AboutUs-desc">Schreibe uns eine DM über Insta bei <a href="https://instagram.com/spicker.kgs">@spicker.kgs</a></li>
  <li className="AboutUs-desc">Schreibe uns eine E-Mail unter <a href="mailto:spicker-kgs@gmx.de">spicker-kgs@gmx.de</a></li>
  <li className="AboutUs-desc">Sprich ein Mitglied der Redaktion einfach persönlich an</li>
</ul>

    </div>
  );
};

export default AboutUs;
