import React, { useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './styles.css';
const Editor = ({ onChange, value }) => {
  const editorRef = useRef();

  useEffect(() => {
    if (editorRef.current && value !== editorRef.current.getData()) {
      editorRef.current.setData(value);
    }
  }, [value]);

  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      onReady={(editor) => {
        editorRef.current = editor;
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
      config={{
        toolbar: [
          'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 
          '|', 'undo', 'redo'
        ],
        removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'ImageToolbar', 'ImageUpload', 'ImageCaption'],
      }}
    />
  );
};

export default Editor;
