import { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from 'dompurify';
import { formatISO9075 } from 'date-fns'
import { UserContext } from "../UserContext";
import { Link, Navigate } from "react-router-dom"
import "./css/PP.css"
const config = require('../config');
const settingsIcon = '⚙️';

export default function PostPage() {
  const { id } = useParams();
  const [postInfo, setPostInfo] = useState(null);
  const [/*isLoggedIn*/, setIsLoggedIn] = useState(false);
  const { userInfo } = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const hasFetched = useRef(false);
  const [coverImg, setCoverImg] = useState("lol");
  const [imgError, setImgError] = useState("");
  const [textStyle, setTextStyle] = useState('default-style');
  const [settingsVisible, setSettingsVisible] = useState(false);

  const toggleSettings = () => {
    setSettingsVisible(!settingsVisible);
  };



  function deletePost() {
    const confirmationCode = Math.floor(Math.random() * 9000) + 1000;
    const input = prompt(`Please enter the confirmation code to delete this post: ${confirmationCode}`);
    if (input === confirmationCode.toString()) { 
      fetch(`${config.backendIP}/postD/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {}
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(data => {
          alert('Post deleted successfully.');
          setRedirect(true);
        })
        .catch(error => {
          alert(`Error deleting post: ${error.message}`);
        });
    } else {
      alert('Confirmation code is incorrect. Post was not deleted.');
    }
  }


  useEffect(() => {

    const getPostData = async () => {
      if (!hasFetched.current) {
          hasFetched.current = true;
          const response = await fetch(`${config.backendIP}/post/${id}`);
          if (response.status !== 200) {
              setError(true);
              if(response.status === 418) {
                setErrorInfo('Dieser Artikel exsistiert nicht.')
              }
              else if (response.status === 503) {
                setErrorInfo('Die Website befindet sich momentan in Wartungsarbeiten. Sorry!')
              }
              throw new Error("Network response was not ok");
          } else {
              const postInfo = await response.json();
              postInfo.content = DOMPurify.sanitize(postInfo.content);

              // Hier wird die Backend-IP zu allen img-Tags im Inhalt hinzugefügt:
              //postInfo.content = postInfo.content.replace(/src=["']\/postImages/g, `src="${config.backendIP}/postImages`);
              try {
              const filenameMatch = postInfo.cover.match(/[\\/](?:.(?![\\/]))+$/); // Dateinamen extrahieren
              const coverFileName = filenameMatch ? filenameMatch[0].substr(1) : postInfo.cover; // Den ersten Schrägstrich oder Backslash entfernen
              setCoverImg(`${config.backendIP}/uploads/${coverFileName}`)
              }
              catch {
                console.log("set Cover Img error")
              }
              setPostInfo(postInfo);
          }
      }
  };

    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
    getPostData().catch(() => setError(true));
  }, [id]);

  const handleStyleChange = (event) => {
    const selectedStyle = event.target.value;
    setTextStyle(selectedStyle);
    console.log("Selected style:", selectedStyle);
    
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (90*24*60*60*1000)); 
    const expires = "expires=" + expiryDate.toUTCString();
    document.cookie = "textStyle=" + selectedStyle + ";" + expires + ";path=/";
  };
  

  const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  

  const setInitialStyle = () => {
    const style = getCookie("textStyle");
    if (style !== "") {
      setTextStyle(style);
    }
  };
  

  useEffect(() => {
    setInitialStyle();
  }, []);
  
  
  if (redirect) {
    return <Navigate to={`/`} />
  }

  if (error) {
    return <div className="IndexPageError">Ein Fehler ist aufgetreten. {errorInfo}</div>;
  }

  if (!postInfo) return '';

  return (
    <div className="post-page">
      <div className="button-group">
      <Link className="back-btn" to={"/"}>Zurück</Link>
        <button onClick={toggleSettings} className="settings-btn">{settingsIcon}</button>
      </div>
      {settingsVisible && (
  <div className="edit_row">
        <div className="selector-container">
    {userInfo.username && (
      <>
        <Link className="edit" to={`/edit/${postInfo._id}`}>Bearbeite diesen Artikel</Link>
        <span onClick={deletePost} className="delete">Diesen Artikel löschen</span>
      </>
    )}

      <label htmlFor="text-style">Wähle einen Textstil:</label>
      <select id="text-style" className="select-style" value={textStyle} onChange={handleStyleChange}>
        <option value="default-style">Standart</option>
        <option value="alternate-style-1">Style 1</option>
        <option value="alternate-style-2">Style 2</option>
        <option value="alternate-style-3">Style 3</option>
      </select>
    </div>
  </div>
)}

      <h1>{DOMPurify.sanitize(postInfo.title)}</h1>
      <h2 className="topic">Thema: {postInfo.topic}</h2>
      <time>{formatISO9075(new Date(postInfo.createdAt))}</time>
      {postInfo.author && <div className="author">Von: {postInfo.author}</div>}
      <div className="image">
        {!imgError && (
          <img src={`${coverImg}`} alt="Einen moment..." onError={() => {
            setImgError(true);
          }}/>
        )}
      </div>
  
      <div className={`content ${textStyle}`} dangerouslySetInnerHTML={{ __html: postInfo.content }} />
    </div>
  );
}
