import './App.css';
import { Route, Routes } from "react-router-dom";
import Layout from './Layout';
import IndexPage from './pages/IndexPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import { UsercontextProvider } from './UserContext';
import CreatePost from './pages/CreatePost';
import PostPage from './pages/PostPage';
import AboutPage from './pages/AboutPage';
import NotFoundPage from './pages/NotFoundPage';
import EditPost from './pages/EditPost';
import AdminPanel from './pages/AdminPage';
import NewsletterSignup from './pages/newsletter';
import NewsletterVerify from './pages/verify';
import HelpSpicker from './pages/helpUs'
import Impressum from './pages/impressum';
import SignoutPage from './pages/singout';
import Datenschutz from './pages/datenschutz';

function App() {
  return (
    <UsercontextProvider>
      <Routes>
        <Route path='/' element={<Layout />} >
          <Route index element={<IndexPage />} />
          <Route path='/login' element={<LoginPage/>} />
          <Route path='/registertomate' element={<RegisterPage />} />
          <Route path='/create' element={<CreatePost />} />
          <Route path='/post/:id' element={<PostPage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='*' element={<NotFoundPage />} />
          <Route path='/edit/:id' element={<EditPost />} />
          <Route path='/signup' element= {<NewsletterSignup />} />
          <Route path='/signup/verify' element= {<NewsletterVerify />} />
          <Route path='/helpUS' element={<HelpSpicker/>} />
          <Route path='/impressum' element={<Impressum/>} />
          <Route path='/adminpanel' element={<AdminPanel />} />
          <Route path='/signout' element={<SignoutPage/>} />
          <Route path='/datenschutz' element={<Datenschutz/>} />
        </Route>
      </Routes>
    </UsercontextProvider>
  );
}

export default App;
