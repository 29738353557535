import { useContext, useEffect, useRef, useState } from "react";
import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "./UserContext";
import "./pages/css/header.css"
const config = require('./config');


export default function Header() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarRef = useRef(null);

  const [imageLoaded, setImageLoaded] = useState(false);
  const AdminPanelOrNot = location.pathname === "/adminpanel";
  const { setUserInfo, userInfo } = useContext(UserContext);
  const hasFetched = useRef(false);
  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      const fetchUserInfo = async () => {
        try {
          const response = await fetch(`${config.backendIP}/profile`, {
            credentials: 'include',
          });
          const userInfo = await response.json();
          setUserInfo(userInfo);
        } catch (error) {
          console.error('Failed to fetch user info:', error);
        }
      };
      fetchUserInfo();
    }
  }, [setUserInfo, AdminPanelOrNot, hasFetched]);

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : 'auto';
  }, [menuOpen]);

  function logout() {
    setMenuOpen(false);
    fetch(`${config.backendIP}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
  }

  const username = userInfo?.username;
  const [link, setLink] = useState("/login");

  useEffect(() => {
    if (userInfo?.isAdmin) {
      setLink("/adminpanel");
    } else {
      setLink("/login");
    }
  }, [userInfo]);

  const aboutOrNot = location.pathname === "/about";
  const newPostOrNot = location.pathname === "/create";
  const newsletterOrNot = location.pathname === "/signup";
  const helpUsOrNot = location.pathname === "/helpUs";
  const startPageOrNot = location.pathname === "/";
  const adminLoginOrNot = location.pathname === "/adminlogin";
  const impOrNot = location.pathname === "/impressum";

  return (
    <header>
      <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className={`overlay ${menuOpen ? 'open' : ''}`} onClick={() => setMenuOpen(false)}></div>

      <div ref={sidebarRef} className={`side-menu ${menuOpen ? 'open' : ''}`}>
        <div>
          {!startPageOrNot && <Link to="/" onClick={() => setMenuOpen(false)}><p>Startseite</p></Link>}
        </div>

        <div className="newsletter">

        {newsletterOrNot ? <Link to="/signup" onClick={() => setMenuOpen(false)} style={{ color: '#888' }}><p>Newsletter</p></Link> :
        <Link to="/signup" onClick={() => setMenuOpen(false)}><p>Newsletter</p></Link>}
        </div>

        <div className="mitmachen">
        {helpUsOrNot ? <Link to="/helpUs" onClick={() => setMenuOpen(false)} style={{ color: '#888' }}><p>Mach mit!</p></Link> :
        <Link to="/helpUs" onClick={() => setMenuOpen(false)}><p>Mach mit!</p></Link>}
        </div>

        <div className="about">
          {aboutOrNot ? (
                    <Link to="/about" className="AboutUs-Header" style={{ color: '#888' }} onClick={() => setMenuOpen(false)}><p>Über uns & Kontakt</p></Link>
                  ) : (
                    <Link to="/about" className="AboutUs-Header" onClick={() => setMenuOpen(false)}><p>Über uns & Kontakt</p></Link>
                  )}
          </div>

        <div>
        {username && <hr></hr>}
        </div>

        <div className="redakteur">
        {username ? (
          newPostOrNot ? (
            <Link to="/create" style={{ color: '#888' }} onClick={() => setMenuOpen(false)}><p>Neuen Artikel erstellen</p></Link>
          ) : (
            <Link to="/create" onClick={() => setMenuOpen(false)}><p>Neuen Artikel erstellen</p></Link>
          )
          ) : (
            <Link to="/login" onClick={() => setMenuOpen(false)}><p>Redakteurbereich</p></Link> )}
        </div>

        <div className="logout">
        {username && <a onClick={logout} href="/">({username}) - Abmelden</a>}
        </div>

        <div className="admin">
        {username && (adminLoginOrNot ? ( <Link to={link}onClick={() => setMenuOpen(false)} style={{color:"#888"}}><p>Adminbereich</p></Link>) : ( <Link to={link} onClick={() => setMenuOpen(false)}><p>Adminbereich</p></Link>) ) }
        </div>

        <div className="imp">
        {impOrNot ? <Link to="/impressum" onClick={() => setMenuOpen(false)}  style={{color:"#888"}}><p>Impressum</p></Link> : <Link to="/impressum" onClick={() => setMenuOpen(false)}><p>Impressum</p></Link>}
        </div>


      </div>

      {!AdminPanelOrNot && (
        <div className="header-logo-container">
          {!imageLoaded && <Link to="/" className="logo">Der Spicker</Link>}
          <Link to="/">
            <img 
              src={`${config.backendIP}/static/spicker.png`} 
              alt="Der Spicker" 
              style={{width: '200px', height: '75px'}}
              onLoad={() => setImageLoaded(true)}
              onError={(e) => { e.target.onerror = null; e.target.style.display='none'; setImageLoaded(false)}}
            />
          </Link>
        </div>
      )}
      <nav>
        {AdminPanelOrNot && (
          <>
            <div>
              <a className="logo" style={{marginRight: 50}} href="/adminpanel">Spicker - Adminpanel</a>
            </div>
            <div>
              <Link to="/" className="logo">Zurück</Link>
            </div>
          </>
        )}
      </nav>
    </header>
  );
}
