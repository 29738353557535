import React from 'react';
import './App.css';

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section contact">
                    <h3>Kontakt</h3>
                    <p><a className="links" href="mailto:info@beispiel.de">spicker-kgs@gmx.de</a></p>
                    <p><a className="links" href="https://instagram.com/spicker.kgs">@spicker.kgs</a></p>
                </div>
                <div className="footer-section links">
                    <h3>Navigation</h3>
                    <ul>
                        <li><a className='links' href="/about">Über uns</a></li>
                        <li><a className="links" href="/impressum">Impressum</a></li>
                        <li><a className="links" href="/datenschutz">Datenschutz</a></li>
                    </ul>
                </div>
                <div className="footer-section credits">
                    <h4>Website Programmiert von</h4>
                    <ul>
                    <li><a href="https://louiscreates.com" style={{color:"white" }}>Louis Rost</a></li>
                    </ul>
                    <h4>Website wird gehostet von</h4>
                    <ul>
                    <li>Herr Schneider</li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                &copy; {new Date().getFullYear()} spicker.kah.gs | Alle Rechte vorbehalten
            </div>
        </footer>
    );
}
