import React from 'react';
import { useState } from 'react';
import "./Popup.css"

const Popup = ({ title, message, buttonText, buttonLink , closeButton}) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
      setIsOpen(false);
    };
  return (
    isOpen && (
    <div className='popup'>
    <div className="popup-box">
      {closeButton && (
        <button onClick={handleClose} className='exit-button'>X</button>
      )}
      <h2 className="popup-title">{title}</h2>
      <p className="popup-message">{message}</p>
      <div>
      <button className="popup-button" onClick={() => window.location.href = buttonLink}>{buttonText}</button>
      </div>
    </div>
    </div>
  )
  );
}

export default Popup;





// HOW TO USE IN HTML:
/*
        <Popup 
          title="Admin access required"
          message="You must be logged in as an admin to access this page."
          buttonText="Log in"
          buttonLink="/adminlogin"
          closeButton="true"
          />
*/