import React from "react";
import { Link } from "react-router-dom";

export default function HelpSpicker() {
    return(
        <div>
           <h2>Werde Teil der Redaktion!</h2>
           <div>
            <div>•Artikel schreiben</div>
            <div>•Fotos</div>
            <div>•Layout/Design der Ausgabe und von Instagram-Posts</div>
            <div>•Fact Checking</div>
            <div>•Interviews führen</div>
            <div>•Umfragen und Statistiken erstellen</div>
            <div>•Die Website betreuen</div>
            <div>•Und vieles mehr</div>
            <div>Es gibt viele verschiedene Aufgaben und für (fast) jeden ist etwas dabei! Hast du Interesse?</div>
            <p></p>
            <div>Melde dich gerne bei uns!</div>
            <h3>Schreibe einen Gastbeitrag!</h3>
            <div>
            Du willst eigentlich kein Mitglied der Redaktion werden, aber du würdest zum Beispiel gerne einen Artikel zu einem bestimmten Thema schreiben oder in irgendeiner Weise etwas zur Ausgabe beisteuern? Du hast zum Beispiel ein gelungenes Gedicht, einen Comic oder einen anderen Text geschrieben und würdest den gerne in der Schülerzeitung veröffentlichen?
            Melde dich gerne bei uns!</div>
            <div>
                <h3>Auch Anregungen und Ideen helfen!</h3>
                <div>Du hast kein Interesse, aktiv mitzumachen und hast auch nichts zu veröffentlichen, aber du wolltest schon immer mal diese eine Sache über die Schule wissen oder hast eine gute Idee für einen Artikel oder etwas anderes?
                Melde dich gerne bei uns!
            </div>
            </div>
            <div>
                <div>
                Wenn du dich bei uns melden willst, kannst du uns einfach eine DM bei Instagram oder eine Mail schreiben oder uns persönlich ansprechen. Kontaktdaten findest du unter: <Link to="/about">Über uns</Link>
                </div>
            </div>
            </div>
        </div>
    )
}

